import React from 'react';
import './projectModal.css';

interface Project {
    id: number;
    title: string;
    cover_photo: string;
    slug: string;
    description?: string;
    images?: { image: string }[]; 
    link?: string;
    key_takeaways?: string;
}

interface ProjectModalProps {
    show: boolean;
    onClose: () => void;
    project: Project | null;
}

const ProjectModal: React.FC<ProjectModalProps> = ({ show, onClose, project }) => {
    if (!show || !project) return null;

    return (
        <div className="modal-overlay" onClick={onClose}>
            <div className="modal-content" onClick={e => e.stopPropagation()}>
                <button className="close-button" onClick={onClose}>×</button>
                
                {/* Cover Photo */}
                <img src={project.cover_photo} alt={project.title} className="small-cover-photo" />

                {/* Title */}
                <h2>{project.title}</h2>

                {/* Description */}
                {project.description && (
                    <>
                        <h3 className="section-title">Description</h3>
                        <hr className="separator" />
                        <div 
                            className="description-text"
                            dangerouslySetInnerHTML={{ __html: project.description }} 
                        />
                    </>
                )}

                {/* Key Takeaways */}
                {(project.key_takeaways || project.images?.length) ? (
                    <>
                        <h3 className="section-title">Key Takeaways</h3>
                        <hr className="separator" />
                        {project.key_takeaways && (
                            <div 
                                className="key-takeaways-text"
                                dangerouslySetInnerHTML={{ __html: project.key_takeaways }} 
                            />
                        )}
                    </>
                ) : null}

                {/* Read More */}
                {project.link && (
                    <>
                        <hr className="separator last-separator" />
                        <a 
                            href={project.link} 
                            className="link-button" 
                            target="_blank" 
                            rel="noopener noreferrer"
                        >
                           See More Here
                        </a>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProjectModal;
