import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './portfolio.css';
import underlineImage from '../assets/underline.png';
import juniperImage from '../assets/juniper.png';
import bankImage from '../assets/bank_churn.png';
import ccImage from '../assets/credit_card.png';

interface Project {
    id: number;
    title: string;
    cover_photo: string;
    slug: string;
    description?: string;
    images?: string[];
    github_link?: string;
}

interface PortfolioProps {
    onProjectClick: (project: Project) => void;
}

const Portfolio: React.FC<PortfolioProps> = ({ onProjectClick }) => {
    const [projects, setProjects] = useState<Project[]>([]);

    useEffect(() => {
        const apiUrl = `${process.env.REACT_APP_API_BASE_URL}/projects/`;

        axios.get<Project[]>(apiUrl)
            .then((response) => {
                setProjects(response.data);
            })
            .catch((error) => {
                console.error('Error fetching projects:', error);
            });
    }, []);

    // Define default projects
    const defaultProjects: Project[] = [
        {
            id: 0,
            title: "Juniper’s Launch in NYC",
            cover_photo: juniperImage,
            slug: "juniper",
        },
        {
            id: 1,
            title: "Bank Customer Churn",
            cover_photo: bankImage,
            slug: "bank-customer-churn",
        },
        {
            id: 2,
            title: "Credit Card Approval Prediction",
            cover_photo: ccImage,
            slug: "credit-card",
        }
    ];

    // If no projects are fetched, use default projects
    const displayedProjects = projects.length > 0 ? projects : defaultProjects;

    return (
        <div>
            <h2>Portfolio</h2>
            <p className="project-intro">
                Here are some of the projects I worked on
            </p>
            <div className="underline-container">
                <img src={underlineImage} alt="Underline" className="underline-image" />
            </div>
            <div className="project-list">
                {displayedProjects.map((project) => (
                    <div key={project.id} className="project" onClick={() => onProjectClick(project)}>
                        <img src={project.cover_photo} alt={project.title} />
                        <h3>{project.title}</h3>
                    </div>
                ))}
            </div>
            {projects.length === 0 && (
                <h3 style={{ marginTop: '7vw', textAlign: 'center' }}>Work in Progress ... Stay tuned!</h3>
            )}

        </div>
    );
};

export default Portfolio;
