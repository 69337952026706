import React, { useRef, useEffect } from 'react';
import About from './About';
import Contact from './Contact';
import Portfolio from './Portfolio';
import './sections.css';
import './home.css';

interface HomeProps {
    onProjectClick: (project: any) => void;
    scrollToSection: (sectionRef: React.RefObject<HTMLElement>) => void;
    homeRef: React.RefObject<HTMLDivElement>;
    aboutRef: React.RefObject<HTMLDivElement>;
    portfolioRef: React.RefObject<HTMLDivElement>;
    contactRef: React.RefObject<HTMLDivElement>;
}

function Home({ onProjectClick, scrollToSection, homeRef, aboutRef, portfolioRef, contactRef }: HomeProps) {
    const textRef = useRef<HTMLHeadingElement>(null);
    const ellipseRef = useRef<SVGEllipseElement>(null);

    useEffect(() => {
        const textElement = textRef.current;
        const ellipseElement = ellipseRef.current;
        const fullText = "Hello , I am Sandy!";
        const helloText = "Hello ";
        let index = 0;

        const startTyping = () => {
            if (textElement) textElement.innerHTML = "";
            index = 0;

            const typingInterval = setInterval(() => {
                if (textElement && index < fullText.length) {
                    const currentText = fullText.slice(0, index + 1);
                    const firstPart = currentText.slice(0, helloText.length);
                    const secondPart = currentText.slice(helloText.length);
                    const styledText = `
                        <span class="italic">${firstPart}</span><span class="highlight">${secondPart}</span>
                    `;
                    textElement.innerHTML = styledText + '&nbsp;';
                    index++;
                } else {
                    clearInterval(typingInterval);
                    setTimeout(() => {
                        if (ellipseElement) {
                            ellipseElement.style.visibility = 'visible';
                            ellipseElement.classList.add('draw');
                        }
                        setTimeout(() => {
                            if (ellipseElement) {
                                ellipseElement.classList.remove('draw');
                                ellipseElement.classList.add('erase');
                            }
                            setTimeout(() => {
                                if (ellipseElement) {
                                    ellipseElement.classList.remove('erase');
                                    ellipseElement.style.visibility = 'hidden';
                                }
                                if (textElement) {
                                    const deletingInterval = setInterval(() => {
                                        if (index > 0) {
                                            const currentText = fullText.slice(0, index - 1);
                                            const firstPart = currentText.slice(0, helloText.length);
                                            const secondPart = currentText.slice(helloText.length);
                                            const styledText = `
                                                <span class="italic">${firstPart}</span><span class="highlight">${secondPart}</span>
                                            `;
                                            textElement.innerHTML = styledText + '&nbsp;';
                                            index--;
                                        } else {
                                            clearInterval(deletingInterval);
                                            setTimeout(startTyping, 1000);
                                        }
                                    }, 100);
                                }
                            }, 2000);
                        }, 2000);
                    }, 500);
                }
            }, 100);
        };

        startTyping();

        const hash = window.location.hash;
        if (hash) {
            const targetElement = document.querySelector(hash);
            if (targetElement) {
                targetElement.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }
    }, []);

    return (
        <div>
            <section id="home" ref={homeRef}>
                <div className="container">
                    <div className="text-ellipse-container">
                        <h2 ref={textRef}></h2>
                        <div className="ellipse-container">
                            <svg className="ellipse">
                                <ellipse cx="50%" cy="50%" rx="1.6em" ry="0.9em" ref={ellipseRef}></ellipse>
                            </svg>
                        </div>
                    </div>
                    <p>I believe analytics is key. My projects revolve around harnessing the power of data and uncovering meaningful insights.</p>
                </div>
            </section>
            <section id="about" ref={aboutRef}>
                <About />
            </section>
            <section id="portfolio" ref={portfolioRef}>
                <Portfolio onProjectClick={onProjectClick} />
            </section>
            <section id="contact" ref={contactRef}>
                <Contact />
            </section>
        </div>
    );
}

export default Home;
