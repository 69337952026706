import './about.css';
import ellipseImage from '../assets/circle.png'; // Adjust the path as needed
import profileImage from '../assets/profile.png'; // Replace this with the actual image filename in your assets folder

const About: React.FC = () => {

    const aboutTitle = "Sandy Yang";
    const aboutParagraphs = [
        "I'm a passionate data enthusiast with a strong focus on analytics and data-driven solutions. My journey into the world of data has been fueled by my curiosity and love for uncovering insights that drive impactful decisions.",
        "Through a combination of hands-on experience with complex projects and continuous self-learning, I’ve honed my skills in data analysis, visualization, and problem-solving. My work revolves around creating meaningful analytics that empower businesses to understand their performance, identify new opportunities, and improve operations. To me, data is much more than just numbers — it's a powerful tool that, when leveraged effectively, can transform the way businesses function and succeed.",
        "I’m always excited to connect and collaborate on interesting projects. Thank you for visiting, and I hope you enjoy exploring my portfolio!"
    ];
    const aboutImage = profileImage; // Path to the profile image

    return (
        <div className="about-container">
            <div className="about-text">
                <div className="highlight-container">
                    <img src={ellipseImage} alt="Ellipse Background" className="ellipse-background" />
                    <h2>
                        <span className="about-highlight">About</span> {aboutTitle}
                    </h2>
                </div>
                {aboutParagraphs.map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                ))}
            </div>
            <div className="about-image">
                <img src={aboutImage} alt={aboutTitle} />
            </div>
        </div>
    );
}

export default About;
