import React, { useRef, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles.css';
import './components/home.css';
import { Button } from 'react-bootstrap';
import Home from './components/Home';
import ProjectModal from './components/ProjectModal';

function App() {
    const homeRef = useRef<HTMLDivElement>(null);
    const aboutRef = useRef<HTMLDivElement>(null);
    const portfolioRef = useRef<HTMLDivElement>(null);
    const contactRef = useRef<HTMLDivElement>(null);

    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedProject, setSelectedProject] = useState<any>(null);

    const handleProjectClick = (project: any) => {
        setSelectedProject(project);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
        setSelectedProject(null);
    };

    const scrollToSection = (sectionRef: React.RefObject<HTMLElement>) => {
        sectionRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    return (
        <div className="App">
            <Router basename="/"> {/* Update basename for GitHub Pages */}
                <nav className="fixed-nav">
                    <div>
                        <Button onClick={() => scrollToSection(homeRef)} className="custom-font-button">Home</Button>
                    </div>
                    <div className="custom-font-button-container">
                        <Button onClick={() => scrollToSection(aboutRef)} className="custom-font-button">About</Button>
                        <Button onClick={() => scrollToSection(portfolioRef)} className="custom-font-button">Portfolio</Button>
                        <Button onClick={() => scrollToSection(contactRef)} className="custom-font-button">Contact</Button>
                    </div>
                </nav>
                <Routes>
                    <Route 
                        path="/" 
                        element={
                            <Home 
                                onProjectClick={handleProjectClick}
                                scrollToSection={scrollToSection}
                                homeRef={homeRef}
                                aboutRef={aboutRef}
                                portfolioRef={portfolioRef}
                                contactRef={contactRef}
                            />
                        } 
                    />
                </Routes>
                <ProjectModal 
                    show={showModal} 
                    onClose={closeModal} 
                    project={selectedProject} 
                />
            </Router>
        </div>
    );
}

export default App;
