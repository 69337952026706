import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import './contact.css';

const Contact: React.FC = () => {
    return (
        <div className="contact-wrapper">
            <div className="container-wrapper">
                <div className="left-container">
                    <div className="top-content">
                        <h2 className="about-heading">
                            Looking forward<br />
                            to working with<br />
                            everyone!
                        </h2>
                        <hr className="custom-line" />
                    </div>
                </div>
                <div className="right-container">
                    <div className="contact-items">
                        <div className="contact-item">
                            <div className="icon-circle">
                                <FontAwesomeIcon icon={faEnvelope} className="icon" />
                            </div>
                            <div>
                                <div>EMAIL</div>
                                <div>
                                    <a href="mailto:sandy.yang.1225@gmail.com" className="hidden-link">
                                        sandy.yang.1225@gmail.com
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="contact-item">
                            <div className="icon-circle">
                                <FontAwesomeIcon icon={faLinkedin} className="icon" />
                            </div>
                            <div>
                                <div>LINKEDIN</div>
                                <div>
                                    <a href="https://www.linkedin.com/in/sandyyang1225" target="_blank" rel="noopener noreferrer" className="hidden-link">
                                        www.linkedin.com/in/sandyyang1225
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="contact-item">
                            <div className="icon-circle">
                                <FontAwesomeIcon icon={faGithub} className="icon" />
                            </div>
                            <div>
                                <div>GITHUB</div>
                                <div>
                                    <a href="https://www.github.com/sandyyang1225" target="_blank" rel="noopener noreferrer" className="hidden-link">
                                        www.github.com/sandyyang1225
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Contact;
